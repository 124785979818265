export const getLabel = (l: StatusLabel[], v: string) => l.find((s : StatusLabel) => s.id === v)?.label ?? v

/** Adapts the accreditation board URL parameters from certificate wishes to training choices context */
export const adaptBoardUrlParams = (url: string) => {
  let result = decodeURIComponent(url)
  const mapping: { [key: string]: string } = {
    accreditations: 'accreditationChoices.accreditation',
    domains: 'accreditationChoices.accreditation.accreditationDomain',
    certificate: 'accreditationChoices.certificate',
    prerequisites: 'accreditationChoices.criterionChoices.criterion',
    acquired: 'certificateWish.acquired',
    establishments: 'user.establishment',
    sites: 'user.site',
    initials: 'user.initials',
  }
  Object.keys(mapping).forEach(key => {
    const regexPattern = new RegExp(`${key}(?=(?:\\[\\])?=)`, 'g')
    result = result.replace(regexPattern, mapping[key])
  })
  return result
}

// Please modify CertificateWishWorkflow constants on id/label changes
export const STATUS_LABEL = [
  {
    id: 'created',
    label: 'accreditation.status.created',
    color: '#CCCCCC',
    disabled: true,
  },
  {
    id: 'requested',
    label: 'accreditation.status.requested',
    color: '#3F51B5',
  },
  {
    id: 'to_engage',
    label: 'accreditation.status.to_engage',
    color: '#3F99B5',
  },
  {
    id: 'in_progress',
    label: 'accreditation.status.in_progress',
    color: '#F9B52C',
  },
  {
    id: 'to_validate',
    label: 'accreditation.status.to_validate',
    color: '#E68421',
  },
  {
    id: 'validated',
    label: 'accreditation.status.validated',
    color: '#12CB81',
  },
  {
    id: 'refused',
    label: 'accreditation.status.refused',
    color: '#A8143C',
  },
  {
    id: 'lost',
    label: 'accreditation.status.lost',
    color: '#D52222',
  },
  {
    id: 'to_engage_disallow',
    label: 'accreditation.status.disallowed',
    color: '#A8143C',
  },
  {
    id: 'disallowed',
    label: 'accreditation.status.disallowed',
    color: '#A8143C',
  },
  {
    id: 'to_engage_pending',
    label: 'accreditation.status.pending',
    color: '#F9B52C',
  },
  {
    id: 'pending',
    label: 'accreditation.status.pending',
    color: '#F9B52C',
  },
  {
    id: 'canceled',
    label: 'accreditation.status.canceled',
    color: '#000000',
  },
  {
    id: 'deleted',
    label: 'accreditation.status.deleted',
    color: '#000000',
    permissions: ['ACCREDITATION_CAN_SEE_DELETED'],
  },
  {
    id: 'suspended',
    label: 'accreditation.status.suspended',
    color: '#000000',
  },
  {
    id: 'archived',
    label: 'accreditation.status.archived',
    color: '#000000',
  },
] as StatusLabel[]

export const STATUS_LABEL_PREREQUISITE = [
  {
    id: 'requested', // training wish statuses = initied | allowed | pending | disallowed
    label: 'accreditation.status.requested',
    color: '#3F51B5',
    useAsFilter: true,
  },
  {
    id: 'to_validate',
    label: 'accreditation.prerequisite.status.to_validate',
    color: '#3F99B5',
    useAsFilter: true,
  },
  {
    id: 'validated',
    label: 'accreditation.prerequisite.status.validated',
    color: '#12CB81',
    useAsFilter: true,
  },
  {
    id: 'refused',
    label: 'accreditation.prerequisite.status.refused',
    color: '#A8143C',
    useAsFilter: true,
  },
  {
    id: 'disallowed',
    label: 'label.status_disallowed',
    color: '#A8143C',
    useAsFilter: true,
  },
  {
    id: 'to_validate_disallow', // training wish status = disallowed_confirmed
    label: 'label.status_disallowed',
    color: '#A8143C',
    useAsFilter: false,
  },
  {
    id: 'to_validate_pending', // training wish status = pending_confirmed
    label: 'label.status_pending',
    color: '#F9B52C',
    useAsFilter: false,
  },
  {
    id: 'pending',
    label: 'label.status_pending',
    color: '#F9B52C',
    useAsFilter: true,
  },
  {
    id: 'canceled',
    label: 'label.status_canceled',
    color: '#000000',
    useAsFilter: true,
  },
  {
    id: 'deleted',
    label: 'deleted',
    color: '#000000',
    useAsFilter: true,
  },
  {
    id: 'lost',
    label: 'accreditation.status.lost',
    color: '#D52222',
    useAsFilter: true,
  },
  {
    id: 'to_realize', // training wish status = to_review
    label: 'accreditation.status.to_realize',
    color: '#3F99B5',
    useAsFilter: true,
  },
  {
    id: 'in_progress',
    label: 'accreditation.status.in_progress',
    color: '#F9B52C',
    useAsFilter: true,
  },
] as StatusLabel[]

export const STATUS_LABEL_TRAINING = [
  {
    id: 'requested',
    label: 'accreditation.status.requested',
    color: '#3F51B5',
    useAsFilter: true,
  },
  {
    id: 'to_validate',
    label: 'accreditation.training.status.to_validate',
    color: '#3F99B5',
    useAsFilter: true,
  },
  {
    id: 'to_realize',
    label: 'accreditation.status.to_realize',
    color: '#3F99B5',
    useAsFilter: true,
  },
  {
    id: 'in_progress',
    label: 'accreditation.training.status.in_progress',
    color: '#F9B52C',
    useAsFilter: true,
  },
  {
    id: 'validated',
    label: 'accreditation.training.status.validated',
    color: '#12CB81',
    useAsFilter: true,
  },
  {
    id: 'refused',
    label: 'accreditation.training.status.refused',
    color: '#A8143C',
    useAsFilter: true,
  },
  {
    id: 'to_validate_disallow',
    label: 'label.status_disallowed',
    color: '#A8143C',
    useAsFilter: false,
  },
  {
    id: 'disallowed',
    label: 'label.status_disallowed',
    color: '#A8143C',
    useAsFilter: false,
  },
  {
    id: 'disallowed_confirmed',
    label: 'label.status_disallowed_confirmed',
    color: '#A8143C',
    useAsFilter: true,
  },
  {
    id: 'to_validate_pending',
    label: 'label.status_pending',
    color: '#F9B52C',
    useAsFilter: false,
  },
  {
    id: 'pending',
    label: 'accreditation.status.pending',
    color: '#F9B52C',
    useAsFilter: false,
  },
  {
    id: 'pending_confirmed',
    label: 'label.status_pending_confirmed',
    color: '#F9B52C',
    useAsFilter: true,
  },
  {
    id: 'canceled',
    label: 'label.status_canceled',
    color: '#000000',
    useAsFilter: true,
  },
  {
    id: 'deleted',
    label: 'deleted',
    color: '#000000',
    useAsFilter: true,
  },
  {
    id: 'lost',
    label: 'accreditation.status.lost',
    color: '#D52222',
    useAsFilter: true,
  },
] as StatusLabel[]

export interface StatusLabel {
  id: string
  label: string
  color: string
  useAsFilter?: boolean
}

// eslint-disable-next-line no-shadow
export const enum CertificateWishType {
  ACCREDITATION,
  RENEWABLE_TRAINING
}

export interface CertificateWishSuspendData {
  suspendDate: string
  suspendComment: string
}

export interface CertificateWishDeleteData {
  deleteDate: string
  deleteComment: string
}
